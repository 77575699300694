import { AccountingSystemOption } from 'shared/accountingSystem.interface'

interface ErpInfoProps {
  accountingSystem: AccountingSystemOption | null
}

export const ErpInfo = ({ accountingSystem }: ErpInfoProps) =>
  getInfoByAccountingSystem(accountingSystem?.value.id)

const getInfoByAccountingSystem = (accountingSystem: string | undefined) => {
  switch (accountingSystem) {
    case 'uni_micro_v3':
      return (
        <div className="alert alert-warning mb-3" role="alert">
          <p>
            Har du spørsmål? Kontakt{' '}
            <a className="text-info-darker" href="mailto: support@unimicro.no">
              support@unimicro.no
            </a> 
          </p>
        </div>
      )

    default:
      return <></>
  }
}

export const SummaryErpInfo = ({ accountingSystem }: ErpInfoProps) =>
  getSummaryInfoByAccountingSystem(accountingSystem?.value.id)

const getSummaryInfoByAccountingSystem = (accountingSystem: string | undefined) => {
  if (!accountingSystem) return <></>
  switch (accountingSystem) {
    case 'uni_micro_v3':
      return (
        <div className="d-flex justify-content-center my-3">
          <p className="mb-5 w-100 w-md-50 alert alert-warning">
          PS! I neste steg vil du bli veiledet til bank for å bestille filene du har behov for.
          Så snart alt er klart i banken, vil Aritma fullføre oppsettet og sende deg nødvendig informasjon som du skal legge inn i Uni Micro V3.
          </p>
        </div>
      )
    default:
      return <></>
  }
}
