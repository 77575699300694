import { useState } from 'react'
import { postOnboardingDetails } from 'utils/api'
import { OnboardingDetails } from 'shared/onboardingDetails.interface'
import { validateOnboardingDetails } from 'shared/onboardingDetails.validator'

export const useFormSubmit = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [instructions, setInstructions] = useState<string>()

  const submitForm = async (form: OnboardingDetails) => {
    if (!validateOnboardingDetails(form)) {
      setIsError(true)
    } else {
      setIsLoading(true)

      await postOnboardingDetails(form)
        .then(response => {
          setIsLoading(false)
          if (response.data.isSuccess) {
            setIsError(response.data.isSuccess)
            setInstructions(response.data.result)
          }
        })
        .catch(_ => setIsLoading(false))
    }
  }

  return { submitForm, isLoading, isError, instructions }
}
