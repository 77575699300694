import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { Navigation } from '../Navigation'
import { ErpInfo } from 'app/components/Erp/ErpInfo'
import { accountingSystemOptions } from 'utils/constants/accountingSystems'
import { FormCard } from 'shared/formCard.interface'
import { OrganizationOption } from 'shared/organizationResponse.interface'
import { AccountingSystemOption } from 'shared/accountingSystem.interface'
import { FormErrorLabel } from 'app/components/Labels/FormErrorLabel'
import { getOrganizations } from 'utils/organizations'
import { createOtherAccountingSystemOption } from 'utils'
import { SubsidiaryCheckbox } from 'app/components/Checkboxes/SubsidiaryCheckbox'
import { emailRegex } from '../../../../shared/onboardingDetails.validator'

type FormData = {
  hasSubsidiaries: boolean
  organization: OrganizationOption
  subsidiaries: OrganizationOption[]
  accountingSystem: AccountingSystemOption
  otherAccountingSystem: string
  sdworxContact: string
}

export const CompanyCard = ({
  form,
  setForm,
  currentPage,
  setCurrentPage,
  disableNavigation,
  onSummaryPage,
}: FormCard) => {
  const [hasSubsidiares, setHasSubsidiaries] = useState<boolean>(false)
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormData>()

  useEffect(() => {
    if (form.subsidiaries?.length > 0) {
      setHasSubsidiaries(true)
      setValue('hasSubsidiaries', true)
      setValue('subsidiaries', form.subsidiaries)
    }

    form.organization && setValue('organization', form.organization)
    form.accountingSystem && setValue('accountingSystem', form.accountingSystem)
    form.sdworxContact && setValue('sdworxContact', form.sdworxContact)
  }, [form.subsidiaries, form.organization, form.accountingSystem, form.sdworxContact, setValue])

  useEffect(() => {
    const subscription = watch(value => {
      setHasSubsidiaries(value.hasSubsidiaries ?? false)
      setForm({
        ...form,
        organization: value.organization as OrganizationOption,
        subsidiaries: value.hasSubsidiaries ? (value.subsidiaries as OrganizationOption[]) : [],
        accountingSystem:
          value.accountingSystem?.value?.id === 'annet'
            ? createOtherAccountingSystemOption(value.otherAccountingSystem)
            : (value.accountingSystem as AccountingSystemOption),
        sdworxContact: value.sdworxContact as string,
      })
      //* Trigger input validation when on summary page, needs timeout to wait until otherAccountingSystem field is registered
      onSummaryPage && setTimeout(() => trigger(), 1)
    })
    return () => subscription.unsubscribe()
  }, [watch, form, setForm, onSummaryPage, trigger])

  const onSubmit = () => setCurrentPage(currentPage + 1)

  return (
    <div className="d-flex justify-content-center align-items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-100 w-md-50">
        <div className="mb-3">
          <SubsidiaryCheckbox register={register} />
        </div>
        <div className="mb-3">
          <label
            id="select-organization-label"
            htmlFor="select-organization"
            className="form-label"
          >
            {hasSubsidiares ? 'Morselskap' : 'Organisasjonsnummer'}
          </label>
          <Controller
            control={control}
            name="organization"
            rules={{ required: 'Vennligst velg ditt selskap.' }}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '3.5px 0',
                    backgroundColor: 'transparent',
                    borderRadius: '0',
                    borderColor: state.isFocused ? '#45095a' : '#ced4da',
                    boxShadow: 'none',
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? '#fb8caf' : '#fff',
                    color: '#45095a',
                  }),
                }}
                classNamePrefix="react-select"
                aria-labelledby="select-organization-label"
                inputId="select-organization"
                placeholder="Søk og velg ditt selskap"
                noOptionsMessage={input =>
                  input.inputValue.length < 2
                    ? 'Søk etter ditt selskap..'
                    : 'Vi kunne ikke finne noen resultater for ditt søk'
                }
                cacheOptions
                loadOptions={getOrganizations}
                loadingMessage={_ => 'Søker..'}
                defaultOptions
                defaultValue={form.organization}
              />
            )}
          />
          <FormErrorLabel label={errors.organization?.message} />
        </div>
        {hasSubsidiares && (
          <div className="mb-3">
            <label
              id="select-subsidiaries-label"
              htmlFor="select-subsidiaries"
              className="form-label"
            >
              Datterselskaper
            </label>
            <Controller
              control={control}
              name="subsidiaries"
              rules={{ required: 'Vennligst velg minst et eller flere datterselskap.' }}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '3.5px 0',
                      backgroundColor: 'transparent',
                      borderRadius: '0',
                      borderColor: state.isFocused ? '#45095a' : '#ced4da',
                      boxShadow: 'none',
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? '#fb8caf' : '#fff',
                      color: '#45095a',
                    }),
                    multiValue: baseStyles => ({
                      ...baseStyles,
                      backgroundColor: '#fb8caf',
                      color: '#45095a',
                    }),
                    multiValueLabel: baseStyles => ({
                      ...baseStyles,
                      backgroundColor: '#fb8caf',
                      color: '#45095a',
                    }),
                  }}
                  classNamePrefix="react-select"
                  aria-labelledby="select-subsidiaries-label"
                  inputId="select-subsidiaries"
                  placeholder="Søk og velg datterselskaper"
                  noOptionsMessage={input =>
                    input.inputValue.length < 2
                      ? 'Søk etter datterselskap..'
                      : 'Vi kunne ikke finne noen resultater for ditt søk'
                  }
                  cacheOptions
                  loadOptions={getOrganizations}
                  loadingMessage={_ => 'Søker..'}
                  defaultOptions
                  defaultValue={form.subsidiaries}
                  isMulti
                />
              )}
            />
            <FormErrorLabel label={errors.subsidiaries?.message} />
          </div>
        )}
        <div className="mb-3">
          <label
            id="select-accounting-system-label"
            htmlFor="select-accounting-system"
            className="form-label"
          >
            Regnskapssystem
          </label>
          <Controller
            control={control}
            name="accountingSystem"
            rules={{ required: 'Vennligst velg ditt regnskapssystem.' }}
            render={({ field }) => (
              <Select
                {...field}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '3.5px 0',
                    backgroundColor: 'transparent',
                    borderRadius: '0',
                    borderColor: state.isFocused ? '#45095a' : '#ced4da',
                    boxShadow: 'none',
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? '#fb8caf' : '#fff',
                    color: '#45095a',
                  }),
                }}
                classNamePrefix="react-select"
                aria-labelledby="select-accounting-system-label"
                inputId="select-accounting-system"
                options={accountingSystemOptions}
                placeholder="Velg ditt regnskapssystem"
                noOptionsMessage={_ => 'Velg Annet om du ikke finner ditt regnskapssystem'}
                isClearable
              />
            )}
          />
          <FormErrorLabel label={errors.accountingSystem?.message} />
        </div>
        {form.accountingSystem?.value.id === 'sdworx' && (
          <div className="mb-3">
            <label
              id="sdworx-contact-label"
              htmlFor="sdworx-contact"
              className="form-label"
            >
              E-postadresse til kontaktperson i SD Worx
            </label>
            <input
              aria-labelledby="sdworx-contact-label"
              id="sdworx-contact"
              type="email"
              className="form-control"
              placeholder="ola.nordmann@gmail.com"
              defaultValue={form.sdworxContact ?? ''}
              {...register('sdworxContact', {
                required: 'Vennligst fyll inn e-postadresse til din kontaktperson i SD Worx.',
                pattern: {
                  value: emailRegex,
                  message: 'Vennligst skriv inn en gyldig e-post adresse.',
                },
              })}
            />
            <FormErrorLabel label={errors.sdworxContact?.message}/>
          </div>
        )
        }
        {form.accountingSystem?.value.id === 'annet' && (
          <div className="mb-3">
            <label
              id="other-accounting-system-label"
              htmlFor="other-accounting-system"
              className="form-label"
            >
              Hvilket regnskapssystem har du idag?
            </label>
            <input
              aria-labelledby="other-accounting-system-label"
              id="other-accounting-system"
              type="text"
              className="form-control"
              placeholder="Ditt nåværende regnskapssystem"
              defaultValue={
                form.accountingSystem?.value.name === 'Annet'
                  ? ''
                  : form.accountingSystem?.value.name
              }
              {...register('otherAccountingSystem', {
                required: 'Vennligst fyll ut ditt regnskapssystem.',
              })}
            />
            <FormErrorLabel label={errors.otherAccountingSystem?.message} />
          </div>
        )}
        {!onSummaryPage && <ErpInfo accountingSystem={form.accountingSystem} />}
        {!disableNavigation && (
          <Navigation currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </form>
    </div>
  )
}
