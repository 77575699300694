interface NavigationProps {
  currentPage: number
  setCurrentPage: (index: number) => void
  continueButtonLabel?: string
}

export const Navigation = ({
  currentPage,
  setCurrentPage,
  continueButtonLabel,
}: NavigationProps) => {
  const handlePreviousButton = () => setCurrentPage(currentPage - 1)

  return (
    <div className="d-flex flex-wrap justify-content-between mt-4">
      <button className="btn btn-primary" type="button" onClick={handlePreviousButton}>
        Tilbake
      </button>
      <button className="btn btn-primary" type="submit">
        {continueButtonLabel ? continueButtonLabel : 'Neste'}
      </button>
    </div>
  )
}
