import { DeepRequired, FieldErrorsImpl, UseFormRegister } from 'react-hook-form'
import { FormErrorLabel } from '../Labels/FormErrorLabel'

type FormData = {
  gdprPermission: boolean
}

interface GdprCheckboxProps {
  register: UseFormRegister<FormData>
  errors: FieldErrorsImpl<DeepRequired<FormData>>
}

export const GdprCheckbox = ({ register, errors }: GdprCheckboxProps) => {
  return (
    <div className="my-3 pt-5 form-check">
      <input
        aria-labelledby="gdpr-permission-label"
        id="gdpr-permission"
        type="checkbox"
        className="form-check-input"
        {...register('gdprPermission', {
          required: 'Du må godta vår personværneklæring for å fullføre.',
        })}
      />
      <label id="gdpr-permission-label" className="form-check-label" htmlFor="gdpr-permission">
        <a
          href="https://www.aritma.com/privacy"
          className="pe-auto text-dark"
          target="_blank"
          rel="noreferrer"
        >
          Samtykke til at Aritma kan bruke mine opplysninger
        </a>
      </label>
      <FormErrorLabel label={errors.gdprPermission?.message} />
    </div>
  )
}
