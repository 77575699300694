import { UseFormRegister } from 'react-hook-form'
import { AccountingSystemOption } from 'shared/accountingSystem.interface'
import { OrganizationOption } from 'shared/organizationResponse.interface'

type FormData = {
  hasSubsidiaries: boolean
  organization: OrganizationOption
  subsidiaries: OrganizationOption[]
  accountingSystem: AccountingSystemOption
  otherAccountingSystem: string
  sdworxContact: string
}

interface SubsidiaryCheckboxProps {
  register: UseFormRegister<FormData>
}

export const SubsidiaryCheckbox = ({ register }: SubsidiaryCheckboxProps) => {
  return (
    <div className="form-check form-switch">
      <input
        aria-labelledby="subsidiary-label"
        id="subsidiary"
        type="checkbox"
        role="switch"
        className="form-check-input"
        {...register('hasSubsidiaries')}
      />
      <label id="subsidiary-label" className="form-check-label" htmlFor="subsidiary">
        Har selskapet datterselskap?
      </label>
    </div>
  )
}
