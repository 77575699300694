import { ReactNode } from 'react'
import { Header } from './Header'

interface CardProps {
  children: ReactNode[] | ReactNode
  currentPage: number
  setCurrentPage: (index: number) => void
}

export const Card = ({ children, currentPage, setCurrentPage }: CardProps) => {
  return (
    <div className="d-flex flex-column w-100 min-vh-100">
      <div className="row mt-3 mt-md-5">
        <Header children={children} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      <div className="row flex-fill align-items-center h-100 my-4">
        {children instanceof Array ? children[currentPage] : children}
      </div>
    </div>
  )
}
