interface ServiceCheckboxProps {
  label: string
  checked: boolean
  available?: boolean | undefined
  onChange: (b: boolean) => void
}

export const ServiceCheckbox = ({ label, checked, available, onChange }: ServiceCheckboxProps) => {
  //* Removes spaces from label to attach it to the id
  const inputId = label.replace(/\W*/g, '').toLowerCase()
  return (
    <div className="mb-3 form-check">
      <input
        aria-labelledby={`${inputId}-label`}
        id={inputId}
        disabled={!available || false}
        type="checkbox"
        className="form-check-input"
        checked={checked}
        onChange={e => available && onChange(e.target.checked)}
      />
      <label id={`${inputId}-label`} className="form-check-label" htmlFor={inputId}>
        {label}
      </label>
    </div>
  )
}
