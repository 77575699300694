import { Crumb } from './Crumb'

interface BreadcrumbProps {
  crumbs: string[]
  currentPageIndex: number
}

export const Breadcrumb = ({ crumbs, currentPageIndex }: BreadcrumbProps) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {crumbs.map((crumb, index) => (
          <Crumb key={index} label={crumb} active={index === currentPageIndex - 1} />
        ))}
      </ol>
    </nav>
  )
}
