interface GetStartedCardProps {
  currentPage: number
  setCurrentPage: (index: number) => void
}

export const GetStartedCard = ({ currentPage, setCurrentPage }: GetStartedCardProps) => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-center position-relative bottom-10">
        <div className="text-white bg-secondary p-3 text-center w-60">
          <h1 className="fs-1 mt-2 mb-4">Velkommen til Aritma Open Banking veileder</h1>
          <h2 className="fs-5 text-primary">
            Dette steget i din bestilling for bankintegrasjon vil sikre at dine filer finner trygt
            fram til ditt ERP-system
            <br />
            <br />
            For å fullføre bestillingen må du også bestille integrasjon i din bank, dette vil du få
            en forklaring på til slutt i denne veilederen
          </h2>
          <button
            type="button"
            className="btn btn-primary text-secondary px-5 py-3 fw-normal fs-5 mt-4 mb-2"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Kom i gang
          </button>
        </div>
      </div>
    </div>
  )
}
