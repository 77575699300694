import { ReactNode, isValidElement } from 'react'
import { Breadcrumb } from 'app/components/Breadcrumb'

import Logo from 'assets/img/logo.svg'

interface HeaderProps {
  children: ReactNode[] | ReactNode
  currentPage: number
  setCurrentPage: (index: number) => void
}

export const Header = ({ children, currentPage, setCurrentPage }: HeaderProps) => {
  const getChildNames = (children: ReactNode[] | ReactNode): string[] =>
    children instanceof Array
      ? children.map(el => isValidElement(el) && el.props.name).filter(children => children)
      : ['']

  return (
    <>
      <div className="col-md-4 col-lg-3">
        <img
          id="logo-header"
          src={Logo}
          className="d-none d-md-block pe-cursor"
          alt="Aritma Logo"
          onClick={() => setCurrentPage(0)}
          aria-label="Gå tilbake til start"
        />
      </div>
      <div className="col-md-8 col-lg-9 d-flex justify-content-end justify-content-center-md align-items-center text-center-md">
        <Breadcrumb crumbs={getChildNames(children)} currentPageIndex={currentPage} />
      </div>
    </>
  )
}
