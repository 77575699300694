import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getBanks } from 'utils/api'
import { Bank, BankOption } from 'shared/bank.interface'

export const useBanks = () => {
  const [banks, setBanks] = useState<BankOption[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const retrieveBanks = async () => {
      setIsLoading(true)

      await getBanks()
        .then(response => {
          const bankOptions: BankOption[] = response.data.map((bank: Bank) => ({
            value: { bic: bank.bic, bankName: bank.bankName },
            label: `${bank.bankName} (${bank.bic})`,
          }))

          setBanks(bankOptions)
          setIsLoading(false)
        })
        .catch(_ =>
          toast.error(
            `Det oppsto en feil under henting av banker. Vennligst forsøk igjen ved å laste inn siden på nytt.`,
          ),
        )
    }

    if (banks.length === 0 && !isLoading) retrieveBanks()
  }, [banks.length, isLoading])

  return { banks, isLoading }
}
