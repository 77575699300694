import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const triggerErrorToast = () =>
  toast.error(
    'Det oppstod en feil ved sendingen av onboardingen din, prøv igjen senere eller kontakt oss på support@aritma.com',
  )

export const Toast = () => (
  <ToastContainer
    position="top-center"
    autoClose={false}
    hideProgressBar
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover={false}
  />
)
