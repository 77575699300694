import { useEffect, useState } from 'react'
import { Card } from 'app/components/Card'
import { LoadingIndicator } from 'app/components/LoadingIndicator'
import { OnboardingInstructions } from 'app/components/OnboardingInstructions'
import { Toast, triggerErrorToast } from 'app/components/Toast'
import { CompanyCard } from 'app/components/Card/pages/CompanyCard'
import { ContactCard } from 'app/components/Card/pages/ContactCard'
import { GetStartedCard } from 'app/components/Card/pages/GetStartedCard'
import { OnboardingCard } from 'app/components/Card/pages/OnboardingCard'
import { SummaryCard } from 'app/components/Card/pages/SummaryCard'
import { useFormSubmit } from 'app/hooks/useFormSubmit'
import { OnboardingDetails } from 'shared/onboardingDetails.interface'

const initialDetails: OnboardingDetails = {
  name: '',
  email: '',
  telephoneNumber: '',
  organization: null,
  subsidiaries: [],
  accountingSystem: null,
  sdworxContact: '',
  bank: null,
  payments: false,
  receivables: false,
  balances: false,
  statements: false,
  gdprPermission: false,
}

export const Overview = () => {
  const [currentPage, setCurrentPage] = useState(0)

  const [form, setForm] = useState<OnboardingDetails>(initialDetails)
  const { submitForm, isLoading, isError, instructions } = useFormSubmit()

  const props = {
    form,
    setForm,
    currentPage,
    setCurrentPage,
    disableNavigation: false,
  }

  useEffect(() => {
    isError && triggerErrorToast()
  }, [isError])

  if (isLoading) return <LoadingIndicator />

  if (instructions) return <OnboardingInstructions instructions={instructions} />

  return (
    <>
      <Toast />
      <Card currentPage={currentPage} setCurrentPage={setCurrentPage}>
        <GetStartedCard currentPage={currentPage} setCurrentPage={setCurrentPage} />
        <ContactCard name="Kontaktinfo" {...props} />
        <CompanyCard name="Selskap" {...props} />
        <OnboardingCard name="Onboarding" {...props} />
        <SummaryCard
          name="Oppsummering"
          {...props}
          disableNavigation
          onFormSubmit={() => submitForm(form)}
        />
      </Card>
    </>
  )
}
