import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { Navigation } from '../Navigation'
import { ServiceCheckbox } from '../../Checkboxes/ServiceCheckbox'
import { AvailableServices } from 'app/components/Erp/AvailableServices'
import { FormErrorLabel } from 'app/components/Labels/FormErrorLabel'
import { useBanks } from 'app/hooks/useBanks'
import { BankOption } from 'shared/bank.interface'
import { FormCard } from 'shared/formCard.interface'
import { Services } from 'shared/services.enum'
import { validateServices } from 'shared/onboardingDetails.validator'
import Modal from '../../Modal'

type FormData = {
  bank: BankOption[]
}

export const OnboardingCard = ({
  form,
  setForm,
  currentPage,
  setCurrentPage,
  disableNavigation,
  onSummaryPage,
}: FormCard) => {
  const { banks, isLoading } = useBanks()

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormData>()

  useEffect(() => {
    form.bank && setValue('bank', form.bank)
  }, [form.bank, setValue])

  useEffect(() => {
    const subscription = watch(value => {
      setForm({
        ...form,
        bank: value.bank as BankOption[],
      })
      //* Trigger input validation when on summary page
      onSummaryPage && trigger()
    })
    return () => subscription.unsubscribe()
  }, [watch, form, setForm, onSummaryPage, trigger])

  const onSubmit = () => setCurrentPage(currentPage + 1)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-100 w-md-50">
        <div className="mb-3">
          <label id="select-bank-label" htmlFor="select-bank" className="form-label">
            Velg din bank
          </label>
          <Controller
            control={control}
            name="bank"
            rules={{ required: 'Vennligst velg din bank.' }}
            render={({ field }) => (
              <Select
                {...field}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: '3.5px 0',
                    backgroundColor: 'transparent',
                    borderRadius: '0',
                    borderColor: state.isFocused ? '#45095a' : '#ced4da',
                    boxShadow: 'none',
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? '#fb8caf' : '#fff',
                    color: '#45095a',
                  }),
                }}
                classNamePrefix="react-select"
                aria-labelledby="select-bank-label"
                inputId="select-bank"
                isMulti
                options={banks}
                isLoading={isLoading}
                isSearchable
                placeholder="Søk på navn eller BIC"
                noOptionsMessage={_ => 'Vi kunne ikke finne noen resultater for ditt søk'}
                defaultValue={form.bank}
              />
            )}
          />
          <FormErrorLabel label={errors.bank?.message} />
        </div>
        <div className="mb-3">
          <label className="form-label">Valgte tjenester</label>
          <ServiceCheckbox
            label={Services.Remittering}
            available={form.accountingSystem?.value.availableServices.includes(
              Services.Remittering,
            )}
            checked={form.payments}
            onChange={b => setForm({ ...form, payments: b })}
          />
          <ServiceCheckbox
            label={Services.Innbetalinger}
            available={form.accountingSystem?.value.availableServices.includes(
              Services.Innbetalinger,
            )}
            checked={form.receivables}
            onChange={b => setForm({ ...form, receivables: b })}
          />
          <ServiceCheckbox
            label={Services.Kontoinformasjon}
            available={form.accountingSystem?.value.availableServices.includes(
              Services.Kontoinformasjon,
            )}
            checked={form.balances}
            onChange={b => setForm({ ...form, balances: b })}
          />
          <div className={'d-flex gap-3'}>
            <ServiceCheckbox
              label={Services.Regnskapsgodkjente_Betalinger}
              available={form.accountingSystem?.value.availableServices.includes(
                Services.Regnskapsgodkjente_Betalinger,
              )}
              checked={form.statements}
              onChange={b => setForm({ ...form, statements: b })}
            />
            {onSummaryPage && !validateServices(form) && (
              <FormErrorLabel label={'Vennligst velg minst en tjeneste.'} />
            )}
            <a href="#" onClick={openModal}>Hva er Regnskapsgodkjente Betalinger?</a>
              <Modal show={isModalOpen} onClose={closeModal} title={"Regnskapsgodkjente betalinger"}>
                <p>Regnskapsgodkjente betalinger er en teknisk løsning som gjør at du kan godkjenne en betaling direkte i Aritma Pay Portal, med BankID og tofaktor-autentisering.</p>
                <p>Da trenger du ikke å godkjenne i nettbank.</p>
              </Modal>
          </div>
        </div>
        {!onSummaryPage && <AvailableServices accountingSystem={form.accountingSystem} />}
        {!disableNavigation && (
          <Navigation currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </form>
    </div>
  )
}
