import {AccountingSystem, AccountingSystemOption} from 'shared/accountingSystem.interface'
import {AllServices, Services} from 'shared/services.enum'

const defaultAccountingSystems: AccountingSystem[] = [
  { name: 'Visma Business', id: 'visma_business', availableServices: AllServices },
  { name: 'Visma Global', id: 'visma_global', availableServices: AllServices },
  {
    name: 'Uni Micro V3',
    id: 'uni_micro_v3',
    availableServices: [Services.Remittering, Services.Kontoinformasjon],
    supportEmail: 'support@unimicro.no',
  },
  { name: 'Agendec', id: 'agendec', availableServices: AllServices },
  { name: 'Fiken', id: 'fiken', availableServices: AllServices },
  {
    name: 'Rambase',
    id: 'rambase',
    availableServices: [Services.Innbetalinger, Services.Kontoinformasjon, Services.Remittering],
  },
  { name: 'Uni Economy', id: 'unieconomy', availableServices: AllServices },
  {
    name: 'Sumango',
    id: 'sumango',
    availableServices: [Services.Innbetalinger, Services.Kontoinformasjon, Services.Remittering],
  },
  { name: 'SD Worx', id: 'sdworx', availableServices: [Services.Remittering, Services.Regnskapsgodkjente_Betalinger] },
  { name: 'Annet', id: 'annet', availableServices: AllServices },
]

export const formatAccountingSystemAsOptions = (
  accountingSystems: AccountingSystem[],
): AccountingSystemOption[] =>
  accountingSystems.map(system => ({
    value: system,
    label: system.name,
  }))

export const accountingSystemOptions = formatAccountingSystemAsOptions(defaultAccountingSystems)
