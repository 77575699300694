import { AccountingSystemOption } from 'shared/accountingSystem.interface'
import { AllServices } from 'shared/services.enum'
import { listOptions } from 'utils'

interface AvailableServicesProps {
  accountingSystem: AccountingSystemOption | null
}

export const AvailableServices = ({ accountingSystem }: AvailableServicesProps) => {
  if (accountingSystem && accountingSystem.value.availableServices.length !== AllServices.length) {
    const mail = accountingSystem.value.supportEmail || 'support@aritma.com'
    const availableServices = accountingSystem.value.availableServices
    return (
      <div className="alert alert-warning mb-3" role="alert">
        <p className="my-1">
          {accountingSystem.label} støtter{' '}
          {availableServices.map((x, index) => {
            if (index === availableServices.length - 1) {
              return 'og ' + x
            } else {
              return x + ' '
            }
          })}
          .{' '}
        </p>
        <p className="my-1">
          Dersom du ønsker flere filtyper, vennligst kontakt{' '}
          <a className="text-info-darker" href={`mailto: ${mail}`}>
            {mail}
          </a>{' '}
          for mer informasjon.
        </p>
      </div>
    )
  } else return <></>
}
