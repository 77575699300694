import { AccountingSystemOption } from 'shared/accountingSystem.interface'
import { AllServices } from 'shared/services.enum'

export const listOptions = (options: string[]): string => {
  const length = options.length
  if (length === 0) return ''
  if (length === 1) return options[0].toLowerCase()
  if (length === 2) return options.join(' og ').toLowerCase()
  else {
    const last = options[length - 1]
    return (options.slice(0, -1).join(', ') + ' og ' + last).toLowerCase()
  }
}

export const hasOnlyDigits = (str: string): boolean => /^[0-9 ]+$/.test(str)

export const createOtherAccountingSystemOption = (
  otherAccountingSystem: string | undefined,
): AccountingSystemOption => ({
  label: 'Annet',
  value: {
    id: 'annet',
    name: otherAccountingSystem ?? '',
    availableServices: AllServices,
  },
})
