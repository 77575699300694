import { toast } from 'react-toastify'
import { hasOnlyDigits } from 'utils'
import { getOrganizationByInput } from 'utils/api'
import { OrganizationNo, OrganizationOption } from 'shared/organizationResponse.interface'

const formatOrganizationOptions = (organizations: OrganizationNo[]): OrganizationOption[] =>
  organizations.map(organization => ({
    value: { name: organization.navn, organizationNumber: organization.organisasjonsnummer },
    label: `${organization.navn} (${organization.organisasjonsnummer})`,
  }))

export const getOrganizations = (
  inputValue: string,
  callback: (options: OrganizationOption[]) => void,
) => {
  if (inputValue.length < 1) return
  //* Removes whitespace from input if inputValue looks like an orgnr
  const input = hasOnlyDigits(inputValue) ? inputValue.replace(/\W/g, '') : inputValue.trim()
  getOrganizationByInput(input)
    .then(res => {
      if (res.data._embedded?.enheter) {
        //* Formats organizations into react-select options
        const organizationOptions = formatOrganizationOptions(res.data._embedded.enheter)
        callback(organizationOptions)
      } else if (input.length === 9 && hasOnlyDigits(input)) {
        //* If no organizations are found and input looks like an organization number, set it as an option for user to choose
        callback([{ label: input, value: { name: input, organizationNumber: input } }])
      } else {
        //* If no results are found, set options to empty
        callback([])
      }
    })
    .catch(_ =>
      toast.error(
        'Det skjedde en feil mens vi lastet inn selskaper, vennligst prøv igjen eller skriv inn organisasjonsnummeret ditt manuelt.',
      ),
    )
}
