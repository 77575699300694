import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Navigation } from '../Navigation'
import { FormErrorLabel } from 'app/components/Labels/FormErrorLabel'
import { emailRegex, telephoneRegex } from 'shared/onboardingDetails.validator'
import { FormCard } from 'shared/formCard.interface'

type FormData = {
  name: string
  email: string
  telephoneNumber: string
}

export const ContactCard = ({
  form,
  setForm,
  currentPage,
  setCurrentPage,
  disableNavigation,
  onSummaryPage,
}: FormCard) => {
  const {
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormData>()

  useEffect(() => {
    const subscription = watch(value => {
      setForm({
        ...form,
        name: value.name as string,
        email: value.email as string,
        telephoneNumber: value.telephoneNumber as string,
      })
      //* Trigger input validation when on summary page
      onSummaryPage && trigger()
    })
    return () => subscription.unsubscribe()
  }, [watch, form, setForm, onSummaryPage, trigger])

  const onSubmit = () => setCurrentPage(currentPage + 1)

  return (
    <div className="d-flex justify-content-center align-items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-100 w-md-50">
        <div className="mb-3">
          <label id="name-label" htmlFor="name" className="form-label">
            Navn
          </label>
          <input
            aria-labelledby="name-label"
            id="name"
            type="text"
            className="form-control"
            placeholder="Ola Nordmann"
            defaultValue={form.name}
            {...register('name', {
              required: 'Vennligst fyll ut navnet ditt.',
              minLength: {
                value: 2,
                message: 'Navnet ditt må bestå av minst 2 tegn.',
              },
            })}
          />
          <FormErrorLabel label={errors.name?.message} />
        </div>
        <div className="mb-3">
          <label id="email-label" htmlFor="email" className="form-label">
            E-postadresse
          </label>
          <input
            aria-labelledby="email-label"
            id="email"
            type="email"
            className="form-control"
            placeholder="ola.nordmann@gmail.com"
            defaultValue={form.email}
            {...register('email', {
              required: 'Vennligst fyll ut e-posten din.',
              pattern: {
                value: emailRegex,
                message: 'Vennligst skriv inn en gyldig e-post adresse.',
              },
            })}
          />
          <FormErrorLabel label={errors.email?.message} />
        </div>
        <div className="mb-3">
          <label id="telephone-number-label" htmlFor="telephone-number" className="form-label">
            Telefonnummer
          </label>
          <input
            aria-labelledby="telephone-number-label"
            id="telephone-number"
            type="tel"
            className="form-control"
            placeholder="Eks. +4712345678"
            defaultValue={form.telephoneNumber}
            {...register('telephoneNumber', {
              required: 'Vennligst fyll ut telefonnummeret ditt.',
              pattern: {
                value: telephoneRegex,
                message:
                  'Vennligst fyll inn et telefonnummer med internasjonalt format (f.eks. +47)',
              },
            })}
          />
          <FormErrorLabel label={errors.telephoneNumber?.message} />
        </div>
        {!disableNavigation && (
          <Navigation currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </form>
    </div>
  )
}
