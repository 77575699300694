import { OrganizationResponse } from '../../shared/organizationResponse.interface'
import { api, onboardingApi } from './api'
import { Bank } from 'shared/bank.interface'
import { OnboardingDetails, formatOnboardingDetails } from 'shared/onboardingDetails.interface'
import { OnboardingResponse } from 'shared/onboardingResponse.interface'
import { hasOnlyDigits } from 'utils'

export const getBanks = async () => {
  return await onboardingApi.get<Bank[]>('onboarding/banks')
}

export const postOnboardingDetails = async (data: OnboardingDetails) => {
  const formattedDetails = formatOnboardingDetails(data)
  return await onboardingApi.post<OnboardingResponse>('onboarding', formattedDetails)
}

export const getOrganizationByInput = async (value: string) => {
  const params =
    value.length === 9 && hasOnlyDigits(value) ? `organisasjonsnummer=${value}` : `navn=${value}`

  return await api.get<OrganizationResponse>(
    `https://data.brreg.no/enhetsregisteret/api/enheter?${params}`,
  )
}
