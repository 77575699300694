import { useForm } from 'react-hook-form'
import { Navigation } from '../Navigation'
import { ContactCard } from './ContactCard'
import { CompanyCard } from './CompanyCard'
import { OnboardingCard } from './OnboardingCard'
import { FormCard } from 'shared/formCard.interface'
import { SummaryErpInfo } from 'app/components/Erp/ErpInfo'
import { GdprCheckbox } from 'app/components/Checkboxes/GdprCheckbox'

type FormData = {
  gdprPermission: boolean
}

export const SummaryCard = ({
  form,
  setForm,
  currentPage,
  setCurrentPage,
  disableNavigation,
  onFormSubmit,
}: FormCard) => {
  const onSummaryPage = true

  const cardProps = {
    form,
    setForm,
    currentPage,
    setCurrentPage,
    disableNavigation,
    onSummaryPage,
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onSubmit = (data: FormData) => data.gdprPermission && onFormSubmit && onFormSubmit()

  return (
    <div>
      <div className="d-flex justify-content-center">
        <p className="text-center text-secondary mb-5 w-50">
          Se over informasjonen du har lagt inn og se at alt er rett. Dersom alt stemmer, vennligst
          godta at vi håndterer dine personopplysninger og trykk{' '}
          <span className="fw-bold">Fullfør</span>. Deretter vil vi sende deg videre til banken din
          for bestilling av bankintegrasjon.
        </p>
      </div>
      <ContactCard name="Kontaktinfo" {...cardProps} />
      <CompanyCard name="Selskap" {...cardProps} />
      <OnboardingCard name="Onboarding" {...cardProps} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <GdprCheckbox register={register} errors={errors} />
        </div>
        <SummaryErpInfo accountingSystem={form.accountingSystem} />
        <Navigation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          continueButtonLabel="Fullfør"
        />
      </form>
    </div>
  )
}
