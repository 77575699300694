import React, { useEffect } from "react";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, title, children }) => {
  // Handle closing when clicking outside the modal
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("modal-backdrop")) {
        onClose();
      }
    };
    if (show) {
      window.addEventListener("click", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div
      className="modal-backdrop"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1050,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="card"
        style={{
          width: "30rem",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "1rem",
        }}
      >
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="modal-title">{title}</h5>
          <button
            type="button"
            className="close"
            onClick={onClose}
            style={{
              position: "relative",
              top: "-1rem",
              fontSize: "2rem",
              background: "transparent",
              border: "none",
              color: "#000",
              cursor: "pointer",
              outline: "none",
            }}
          >
            &times;
          </button>
        </div>
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
