export enum Services {
  Remittering = 'Remittering',
  Innbetalinger = 'Innbetalinger',
  Kontoinformasjon = 'Bankavstemming/Kontoinformasjon',
  Regnskapsgodkjente_Betalinger = 'Regnskapsgodkjente Betalinger',
}

export const AllServices = [
  Services.Remittering,
  Services.Innbetalinger,
  Services.Kontoinformasjon,
  Services.Regnskapsgodkjente_Betalinger,
]

export const NoServices: Services[] = []
