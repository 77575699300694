import { App } from 'app/App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'assets/styles/index.scss'

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
