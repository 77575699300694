import { AccountingSystemOption } from './accountingSystem.interface'
import { Bank, BankOption } from './bank.interface'
import { Organization, OrganizationOption } from './organizationResponse.interface'

export interface OnboardingDetails {
  name: string
  email: string
  telephoneNumber: string
  organization: OrganizationOption | null
  subsidiaries: OrganizationOption[]
  accountingSystem: AccountingSystemOption | null
  sdworxContact: string
  bank: BankOption[] | null
  payments: boolean // Remittering
  receivables: boolean // Innbetalinger
  balances: boolean // Kontoinformasjon
  statements: boolean // Regnskapsgodkjente betalinger
  gdprPermission: boolean
}

export interface FormattedOnboardingDetails {
  name: string
  email: string
  telephoneNumber: string
  organization: Organization
  subsidiaries: Organization[]
  accountingSystem: string
  bank: Bank[]
  payments: boolean // Remittering
  receivables: boolean // Innbetalinger
  balances: boolean // Kontoinformasjon
  statements: boolean // Regnskapsgodkjente betalinger
  gdprPermission: boolean
}

export const formatOnboardingDetails = (
  details: OnboardingDetails,
): FormattedOnboardingDetails => ({
  ...details,
  organization: details.organization!.value,
  subsidiaries: details.subsidiaries ? details.subsidiaries.map(s => s.value) : [],
  bank: details.bank!.map(b => b.value),
  accountingSystem: details.accountingSystem!.value.name,
  gdprPermission: true,
})
