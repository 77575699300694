import { OnboardingDetails } from './onboardingDetails.interface'
import { AccountingSystemOption } from './accountingSystem.interface'
import { Bank } from './bank.interface'
import { OrganizationOption } from './organizationResponse.interface'

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
export const telephoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/

export const validateName = (name: string) => name.length >= 2

export const validateTelephoneNumber = (telephoneNumber: string) => {
  const regex = new RegExp(telephoneRegex)
  return regex.test(telephoneNumber)
}

export const validateEmail = (email: string) => {
  const regex = new RegExp(emailRegex)
  return regex.test(email)
}

export const validateServices = (details: OnboardingDetails) =>
  details.payments || details.receivables || details.balances || details.statements

const validateOrganization = (organization: OrganizationOption | null) => organization !== null

const validateAccountingSystem = (accountingSystem: AccountingSystemOption | null) =>
  accountingSystem !== null && accountingSystem.value.name !== ''

const validateBank = (bank: Bank[] | undefined) => bank !== undefined

export const validateOnboardingDetails = (details: OnboardingDetails): boolean => {
  return (
    validateName(details.name) &&
    validateTelephoneNumber(details.telephoneNumber) &&
    validateEmail(details.email) &&
    validateOrganization(details.organization) &&
    validateAccountingSystem(details.accountingSystem) &&
    validateBank(details.bank?.map(b => b.value)) &&
    validateServices(details)
  )
}
