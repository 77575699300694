export const LoadingIndicator = () => {
  return (
    <div className="d-flex align-items-center vh-100">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
